@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 16px;
  min-width: 100%;
  overflow: hidden;
  background: url(../images/common/body_pt.png);
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  padding-bottom: 100px;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold !important;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

a {
  transition: 1s;
}

.imgR, .imgL {
  float: none	!important;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 640px) {
  .hed_left {
    width: auto;
    margin-right: 30px;
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .hed_left {
    width: auto;
  }
}

.hed_right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .hed_right {
    justify-content: center;
  }
}

@media only screen and (max-width: 834px) {
  .hed_right {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: #fff;
    padding: 5px;
  }
}

@media only screen and (max-width: 834px) {
  .hed_right .time {
    display: none;
  }
}

.hed_right .contR {
  width: 50%;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .hed_right .contR {
    width: auto;
  }
}

.hed_right .hed_cont a {
  background: #dc4c35;
  color: #ffffff;
  font-size: 1.25em;
  padding: 20px 30px;
  display: block;
  height: 100%;
  border-radius: 10px;
  text-align: left;
  font-weight: bold;
  width: 300px;
}

@media only screen and (max-width: 834px) {
  .hed_right .hed_cont a {
    width: 100%;
    margin: 0 auto;
    font-size: 1em;
    padding: 20px;
  }
}

.hed_right .hed_shop {
  margin-right: 15px;
  width: 48%;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .hed_right .hed_shop {
    width: auto;
  }
}

.hed_right .hed_shop a {
  text-align: center;
  background: #ea8811;
  color: #ffffff;
  font-size: 1.25em;
  padding: 32px;
  display: block;
  height: 100%;
  border-radius: 10px;
  font-weight: bold;
  width: 300px;
  height: 94px;
}

@media only screen and (max-width: 834px) {
  .hed_right .hed_shop a {
    width: 100%;
    margin: 0 auto;
  }
}

.hed_center {
  margin-right: 50px;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  .hed_center {
    margin-right: 0;
  }
}

.time {
  font-size: 13px;
}

@media only screen and (max-width: 834px) {
  #header {
    justify-content: center;
  }
}

#header .LRwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

@media only screen and (max-width: 640px) {
  #header .LRwrap {
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .LRwrap {
    padding: 30px 10px !important;
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #header .LRwrap {
    padding: 30px 10px !important;
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header .LRwrap {
    padding: 0 10px;
  }
}

#header #siteID {
  text-align: center;
  padding-left: 10px;
}

@media only screen and (max-width: 640px) {
  #header #siteID {
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #header #siteID {
    margin-bottom: 15px;
  }
}

#header #siteID a {
  opacity: 1;
  display: block;
}

.center_rightwrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  .center_rightwrap {
    display: block;
    width: auto;
  }
}

#hed_info .address {
  text-align: right;
}

@media only screen and (max-width: 640px) {
  #hed_info .address {
    text-align: center;
  }
}

.navwrap {
  padding: 0 !important;
  transition: 0.5s ease-in-out;
}

@media screen and (min-width: 834px) and (max-width: 1540px) {
  .navwrap {
    width: 100%;
  }
}

.subfixed {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.hedwrap {
  border-bottom: 5px solid #ea8811;
}

#nav_global {
  width: 100%;
  padding: 10px 0;
  background: #fff;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

#nav_global .inner {
  padding: 0;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul .pcnone {
  display: none;
}

#nav_global ul .spnone {
  display: block;
}

@media only screen and (max-width: 640px) {
  #nav_global ul .spnone {
    display: none;
  }
}

#nav_global ul li {
  z-index: 1;
  font-size: 13px;
  position: relative;
  border-left: 1px solid #b0ca71;
  width: 100%;
}

#nav_global ul li:first-of-type {
  border-left: none;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #nav_global ul li {
    font-size: 14px;
  }
  #nav_global ul li br {
    display: block !important;
  }
}

#nav_global ul li br {
  display: none;
}

#nav_global ul li span {
  display: block;
}

#nav_global ul li a {
  width: 100%;
  display: block;
  color: #333333;
  padding: 0 10px;
  font-weight: bold;
}

#nav_global ul li a span {
  font-size: 10px;
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul ul {
  position: absolute;
  width: 170px;
  z-index: 100;
  display: block;
  background: rgba(255, 154, 0, 0.8);
  text-align: center;
  left: -33%;
  right: 0;
}

#nav_global ul ul li {
  border-bottom: 1px solid #fff;
  font-size: 14px;
}

#nav_global ul ul li:last-of-type {
  border-bottom: none;
}

#nav_global ul ul li a {
  color: #ffffff;
  padding: 15px 0;
}

.mainArea {
  width: 100%;
}

.mainwrap {
  background: url(../images/common/pink_pt.png);
  padding: 50px 0;
}

#local-keyvisual {
  position: relative;
  background-position: center top;
  z-index: 0;
}

#local-keyvisual h1 {
  color: #333333;
  font-size: 5em;
  z-index: 1;
  position: relative;
  text-align: center;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  padding: 150px 0;
  line-height: 0.9em;
  text-shadow: 2px 2px 0 #fff;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    padding: 80px 0;
    font-size: 2.5em;
  }
}

#local-keyvisual h1 span {
  display: block;
  font-size: 20px;
  padding-top: 1.5em;
  text-shadow: none;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 400;
  line-height: 1em;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 span {
    padding-top: 1em;
  }
}

#local-keyvisual h1:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center bottom;
}

#local-keyvisual-title {
  background-size: cover;
}

.pan1 {
  margin: 20px 0 100px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

html, body {
  min-height: 100%;
}

#wrap {
  height: 100%;
  padding: 72px 10px 0;
  overflow-x: hidden;
  position: relative;
}

.button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.onlineShops {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 92px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background-size: cover;
}

.header .orangeTypeLink,
.header .tipsLink,
.header .aboutLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: no-repeat right top;
  background-size: auto auto;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;
  letter-spacing: 0.2em;
  -webkit-transition: width .5s ease;
  transition: width .5s ease;
  position: absolute;
}

.header .orangeTypeLink span,
.header .tipsLink span,
.header .aboutLink span {
  display: block;
  margin-right: 14px;
}

.header .orangeTypeLink:hover,
.header .tipsLink:hover,
.header .aboutLink:hover {
  width: 57px;
}

.header .orangeTypeLink {
  height: 149px;
  width: 47px;
  color: #fff;
  background-image: url(../img/common/bg_header-menu01.png);
  top: 25px;
  left: -28px;
}

.header .tipsLink {
  height: 159px;
  width: 47px;
  background-image: url(../img/common/bg_header-menu02.png);
  top: 184px;
  left: -28px;
}

.header .aboutLink {
  height: 151px;
  width: 48px;
  color: #fff;
  background-image: url(../img/common/bg_header-menu03.png);
  top: 358px;
  left: -14px;
}

.headerLogo {
  margin: 0 18px 0 auto;
  width: 113px;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 62px;
  width: 62px;
  background-size: contain;
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #b0ca71;
  border-radius: 10px;
}

@media only screen and (max-width: 640px) {
  .toggleMenu {
    width: 12%;
  }
}

.hide {
  transform: translateY(-200%);
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 4px;
  border-radius: 30%;
  background-color: #fff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: 0;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 100%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-size: cover;
  padding-top: 200px !important;
}

.overlayMenu > .outer .inner {
  padding: 0 80px;
}

.overlayMenu > .outer .hed_right {
  background: none !important;
  position: inherit;
  padding: 10px;
  border-top: none;
  margin-top: 30px;
}

.overlayMenu > .outer .hed_right .hed_cont {
  max-width: 300px;
  margin: 0 auto;
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .hed_right .time {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .hed_right {
    display: block;
  }
  .overlayMenu > .outer .hed_right .tel2 {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer {
    padding: 20px 0;
  }
  .overlayMenu > .outer .spcont_btn_wrap {
    margin-top: 30px;
  }
  .overlayMenu > .outer .spcont_btn_wrap a {
    padding: 0 !important;
  }
  .overlayMenu > .outer .spcont_btn_wrap img {
    width: 60% !important;
  }
}

.overlayMenu > .outer li {
  list-style: disc;
  font-size: 26px;
  color: #b0ca71;
}

.overlayMenu > .outer li a {
  color: #333;
  padding: 10px;
  display: block;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}

.sp_menu {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .sp_menu {
    justify-content: center;
  }
}

.sp_menu ul li {
  text-align: left;
  font-size: 18px;
}

.sp_menu .inner {
  padding: 0;
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

.overlayMenu .onlineShops {
  margin-top: 42px;
}

.overlayMenu .corporateLink {
  margin-top: 10px;
  height: 55px;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background: url(../img/common/bg_corporate-site.jpg) no-repeat center;
  background-size: cover;
}

.overlayMenuTitle {
  display: block;
  margin-top: 64px;
  font-size: 21px;
  font-weight: bold;
  color: #016411;
  text-align: center;
  letter-spacing: 0.1em;
  position: relative;
}

.overlayMenuTitle:after {
  content: "";
  margin: auto;
  height: 22px;
  width: 100px;
  background-size: contain;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.overlayMenuList {
  margin-top: 64px;
  font-size: 16px;
  font-weight: bold;
  color: #016411;
  letter-spacing: 0.1em;
}

.overlayMenuList li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: no-repeat right center;
}

.overlayMenuList li + li {
  margin-top: 22px;
}

.overlayMenuList li:after {
  content: "";
  margin: 0 40px 0 1em;
  background: url(../img/common/bg_dots01.png) repeat-x left center;
  background-size: 3px auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.overlayMenuList li:nth-of-type(2) {
  background-image: url(../img/common/img_menu-orange01.png);
  background-size: auto 23px;
}

.overlayMenuList li:nth-of-type(1),
.overlayMenuList li:nth-of-type(3) {
  background-image: url(../img/common/img_menu-orange02.png);
  background-size: 22.5px 19.5px;
}

.overlayMenuList li:nth-of-type(4) {
  background-image: url(../img/common/img_menu-orange03.png);
  background-size: 21px 25px;
}

.copyright {
  padding: 28px 0;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.1em;
}

.topIntroPicture {
  margin: 50px auto 0;
  width: 287px;
}

.topIntroCatch {
  display: block;
  font-size: 21px;
  font-weight: bold;
  color: #016411;
  line-height: 2.95;
  letter-spacing: 0.1em;
  text-align: center;
}

.topIntroCatch span {
  position: relative;
}

.topIntroCatch span:before {
  content: "";
  height: 131px;
  width: 270px;
  background: url(../img/top/bg_intro-catch.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 36px;
  left: -41px;
}

.topIntroText {
  margin: 32px 14px 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 2.15;
  letter-spacing: 0.05em;
}

.orangeTypes {
  margin-top: 36px;
  padding: 16px 28px 32px;
  position: relative;
}

.orangeTypes:before, .orangeTypes:after {
  content: "";
  z-index: 2;
  position: absolute;
}

.orangeTypes:before {
  border: solid 3px #016411;
  top: 8px;
  right: 10px;
  bottom: 0;
  left: 10px;
}

.orangeTypes:after {
  border: solid 2px #016411;
  top: 14px;
  right: 16px;
  bottom: 6px;
  left: 16px;
}

.orangeTypes .decoration:before, .orangeTypes .decoration:after {
  content: "";
  background: no-repeat center;
  background-size: contain;
  position: absolute;
  top: 0;
}

.orangeTypes .decoration:before {
  height: 192px;
  width: 130px;
  background-image: url(../img/types/bg_top-left.png);
  left: 0;
}

.orangeTypes .decoration:after {
  height: 203px;
  width: 152px;
  background-image: url(../img/types/bg_top-right.png);
  right: 0;
}

.orangeTypes .inner {
  padding-top: 180px;
  z-index: 3;
  position: relative;
}

.orangeTypes .moreButton {
  display: block;
  margin-top: 30px;
  padding-top: 16px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  background: url(../img/common/ico_bottom.svg) no-repeat center bottom 15px, url(../img/types/bg_orange-more.png) no-repeat center;
  background-size: 22px auto, cover;
  height: 65px;
}

.orangeTypesTitle {
  color: #016411;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
}

.orangeTypesTitle span {
  position: relative;
}

.orangeTypesTitle span:before {
  content: "";
  height: 135px;
  width: 181px;
  background: url(../img/top/img_types-title.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: -94px;
  left: -8px;
}

.orangeTypesList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.orangeTypesList li {
  position: relative;
}

.orangeTypesList a {
  display: block;
  height: 100%;
  width: 100%;
}

.orangeTypesList .title {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #016411;
  text-align: center;
  position: absolute;
  top: 26px;
  left: 0;
}

.orangeTypesList figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.orangeTypesList img {
  margin-bottom: 23px;
  width: auto;
}

.citrusHeading {
  margin-top: 15px;
  text-align: center;
  position: relative;
}

.citrusHeading span {
  display: inline-block;
  margin-top: 132px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #016411;
  letter-spacing: 0.1em;
  position: relative;
}

.citrusHeading span:before {
  content: "";
  margin: auto;
  height: 29px;
  width: 30px;
  background: url(../img/types/img_heading-orange.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
}

.citrusHeading span:after {
  content: "";
  height: 5px;
  width: 100%;
  background: url(../img/common/img_waves.svg) repeat-x center;
  background-size: auto 100%;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.citrusHeading h1 {
  margin-top: 28px;
  font-size: 25px;
  color: #016411;
  letter-spacing: 0.1em;
}

.citrusHeading small {
  margin-left: 10px;
  font-family: "Cabin Sketch", cursive;
  font-size: 12px;
  vertical-align: middle;
  letter-spacing: .1em;
}

.citrusHeading:before, .citrusHeading:after {
  content: "";
  background: no-repeat center;
  background-size: contain;
  position: absolute;
  top: 0;
}

.citrusHeading:before {
  height: 192px;
  width: 130px;
  background-image: url(../img/types/bg_top-left.png);
  left: -12px;
}

.citrusHeading:after {
  height: 203px;
  width: 152px;
  background-image: url(../img/types/bg_top-right.png);
  right: -11px;
}

.citrusData {
  margin: 22px 0 34px;
}

.citrusContents {
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.05em;
}

.citrusContents > * + * {
  margin-top: 25px;
}

.citrusContents hr {
  border: solid 2px #f0ede6;
}

.citrusFigure {
  text-align: center;
}

.citrusFigure figure {
  display: inline-block;
  max-width: 682px;
}

.citrusFigure figcaption {
  margin-top: 7px;
  font-size: 12px;
}

.citrusHeading01 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.77;
  letter-spacing: 0.1em;
}

.citrusHeading02 {
  padding: 9px 1em 9px 47px;
  line-height: 1.6;
  font-size: 20px;
  letter-spacing: 0.1em;
  border-top: solid 2px #f0ede6;
  border-bottom: solid 2px #f0ede6;
  background: url(../img/types/img_heading-orange.png) no-repeat left 5px center;
  background-size: auto 29px;
}

.citrusHeading03 {
  line-height: 1;
}

.citrusHeading03 span {
  display: inline-block;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #016411;
  line-height: 1.6;
  letter-spacing: 0.1em;
  position: relative;
}

.citrusHeading03 span:after {
  content: "";
  height: 5px;
  width: 100%;
  background: url(../img/common/img_waves.svg) repeat-x left top;
  background-size: 119px 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.citrusCalendarHeading {
  margin-top: 48px;
  text-align: center;
  position: relative;
}

.citrusCalendarHeading h2 {
  font-family: "A-OTF 見出ゴMB1 Std DeBold", "MiGoMB1Std-DeBold", "見出ゴMB1", "Midashi Go MB1", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.24em;
}

.citrusCalendarHeading img {
  display: inline-block;
  margin-top: 8px;
  width: 105px;
}

.citrusCalendarHeading:after {
  content: "";
  height: 76px;
  width: 57px;
  background: url(../img/types/img_calendar-girl.png) no-repeat center;
  background-size: contain;
  z-index: 1;
  position: absolute;
  bottom: -33px;
  left: 0;
}

.citrusCalendar {
  margin: 30px -10px 0;
  padding: 40px 15px 20px;
  background: url(../img/types/bg_calendar.jpg) repeat left top;
  background-size: 100% auto;
}

.citrusCalendar .moreButton {
  height: 60px;
  width: 100%;
  background: url(../img/types/bg_calendar-more.jpg) no-repeat center;
  background-size: cover;
  font-family: "A-OTF 見出ゴMB1 Std DeBold", "MiGoMB1Std-DeBold", "見出ゴMB1", "Midashi Go MB1", sans-serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #fff;
}

.citrusCalendarList {
  overflow: hidden;
  -webkit-transition: height .8s ease;
  transition: height .8s ease;
}

.citrusCalendarList li {
  margin-bottom: 50px;
  position: relative;
}

.citrusCalendarList li:after {
  content: "";
  height: 1px;
  width: 100%;
  background: url(../img/types/bg_calendar-border.png) repeat-x left top;
  background-size: auto 100%;
  position: absolute;
  bottom: -30px;
  left: 0;
}

.citrusCalendarList dt {
  font-family: "A-OTF 見出ゴMB1 Std DeBold", "MiGoMB1Std-DeBold", "見出ゴMB1", "Midashi Go MB1", sans-serif;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.1em;
}

.citrusCalendarList dt img {
  width: auto;
  margin-right: 16px;
  vertical-align: middle;
}

.citrusCalendarList dt span {
  vertical-align: middle;
}

.citrusCalendarList dd {
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.85;
  letter-spacing: 0.05em;
}

.citrusSeason .text {
  display: inline-block;
  width: 86px;
  font-family: "A-OTF 見出ゴMB1 Std DeBold", "MiGoMB1Std-DeBold", "見出ゴMB1", "Midashi Go MB1", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.citrusSeason .label {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  height: 40px;
  width: 135px;
  letter-spacing: 0.05em;
  line-height: 40px;
  text-align: center;
  background-color: #fdb100;
  color: #fff;
  border-radius: 2px;
}

.citrusHeading04 {
  line-height: 1;
}

.citrusHeading04 span {
  display: inline-block;
  padding: 3px 10px;
  background: url(../img/types/bg_dl01.jpg) no-repeat center;
  background-size: cover;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: 0.1em;
}

.citrusDl02 dt {
  padding: 12px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: url(../img/types/bg_dl02.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  position: relative;
}

.citrusDl02 dt:before {
  content: "";
  border: solid 2px #fff;
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

.citrusIngredient {
  margin-top: 62px;
}

.citrusIngredientHeading {
  margin-bottom: 10px;
  padding-left: 70px;
  font-size: 18px;
  letter-spacing: 0.1em;
  position: relative;
}

.citrusIngredientHeading:before {
  content: "";
  height: 87px;
  width: 60px;
  background: url(../img/types/ico_measure.png) no-repeat center;
  background-size: contain;
  position: absolute;
  bottom: -11px;
  left: 9px;
}

.citrusIngredientList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 15px;
  background-color: #f0ede6;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.1em;
}

.citrusIngredientList dt, .citrusIngredientList dd {
  padding: 18px 0;
}

.citrusIngredientList dt {
  width: 54.2372881%;
  text-align: center;
  color: #fff;
  background: no-repeat center;
  background-size: cover;
}

.citrusIngredientList dd {
  padding-left: 15px;
  width: 45.7627118644%;
  background-color: #fff;
}

.purchaseButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 40px auto 0;
  height: 60px;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background: url(../img/common/ico_next-white.svg) no-repeat right 13px center, url(../img/types/bg_purchase-btn.jpg) no-repeat center;
  background-size: auto 22px, cover;
  letter-spacing: 0.1em;
  max-width: 325px;
}

.citrusRecipeHeading {
  font-size: 20px;
  letter-spacing: 0.1em;
  text-align: center;
  position: relative;
}

.citrusRecipeHeading:after {
  content: "";
  margin: auto;
  height: 32px;
  width: 189px;
  background: url(../img/types/bg_recipe-text.png);
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: -33px;
  left: 0;
}

.citrusRecipeHeading .color01 {
  color: #52a50a;
}

.citrusRecipeHeading .color02 {
  color: #ffb700;
}

.citrusRecipeHeading .color03 {
  color: #008fb6;
}

.citrusRecipe {
  margin-top: 62px;
}

.sideTipsHead {
  margin: 48px auto 0;
  height: 151px;
  width: 260px;
  background: url(../img/common/tit_side-mikan-tips.png) no-repeat center;
  background-size: contain;
  position: relative;
}

.sideTipsHead span {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 63px;
  left: 21px;
  letter-spacing: 0.1em;
}

.sideTipsList {
  margin: 20px auto 0;
  width: 260px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.54;
  letter-spacing: 0.1em;
}

.sideTipsList li {
  height: 55px;
  background: url(../img/common/bg_side-tips.png) no-repeat center;
  background-size: contain;
}

.sideTipsList a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 40px;
  padding-right: 1em;
  height: 100%;
  width: 100%;
}

.sideTipsList li + li {
  margin-top: 10px;
}

.sideTipsMore {
  display: inline-block;
  margin: 20px 0 0 10px;
  padding-left: 30px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.1em;
  position: relative;
  -webkit-transition: transform .5s ease;
  transition: transform .5s ease;
}

.sideTipsMore:before {
  content: "";
  height: 19px;
  width: 12px;
  background: url(../img/common/ico_next-black.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 12px;
}

.sideTipsMore:hover {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.orangeTips {
  margin: 76px 15px 0;
}

.orangeTipsTitle {
  height: 192px;
  padding: 34px 0 0 14px;
  background: url(../img/top/bg_tips.png) no-repeat center;
  background-size: cover;
  position: relative;
}

.orangeTipsTitle .heading {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0.1em;
  position: relative;
}

.orangeTipsTitle .heading:before {
  content: "";
  height: 36px;
  width: 226px;
  background: url(../img/top/img_tips-heading.png) no-repeat center;
  background-size: contain;
  position: absolute;
  bottom: -55px;
  left: 0;
}

.orangeTipsTitle .more {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.1em;
  position: absolute;
  bottom: 25px;
  left: 38px;
}

.orangeTipsTitle .more:before {
  content: "";
  height: 22px;
  width: 13px;
  background: url(../img/common/ico_next-black.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  top: -2px;
  left: -23px;
}

.orangeTipsTitle:before {
  content: "";
  height: 57px;
  width: 111px;
  background: url(../img/top/img_tips-hat.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: -46px;
  right: 5px;
}

.orangeTipsList {
  margin-top: 20px;
}

.orangeTipsList li {
  height: 65px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  background: url(../img/tips/bg_tips-item02.png) no-repeat left center, url(../img/tips/bg_tips-item01.jpg) no-repeat center;
  background-size: 32px 27px,cover;
}

.orangeTipsList a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 43px;
}

.tipsArchiveHead {
  margin-top: 60px;
  padding-top: 52px;
  height: 186px;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  background: url(../img/tips/bg_tips-title.png) no-repeat center;
  background-size: cover;
  position: relative;
}

.tipsArchiveHead:before, .tipsArchiveHead:after {
  content: "";
  position: absolute;
}

.tipsArchiveHead:before {
  margin: auto;
  height: 57px;
  width: 111px;
  background: url(../img/tips/img_title-hat.png) no-repeat center;
  background-size: contain;
  top: -46px;
  left: 0;
  right: 0;
}

.tipsArchiveHead:after {
  margin: auto;
  height: 36px;
  width: 226px;
  background: url(../img/tips/img_sub-title.png) no-repeat center;
  background-size: contain;
  top: 100px;
  left: 0;
  right: 0;
}

.tipsArchiveList {
  margin-top: 25px;
}

.tipsArchiveList .title {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.64;
  letter-spacing: 0.1em;
  position: relative;
}

.tipsArchiveList .title:after {
  content: "";
  height: 2px;
  background: url(../img/tips/bg_title-dots.png) repeat-x left top;
  background-size: auto 100%;
  position: absolute;
  left: 0;
  bottom: -5px;
  right: 0;
}

.tipsArchiveList .excerpt {
  margin-top: 20px;
  font-size: 12px;
  line-height: 2;
  letter-spacing: 0.05em;
}

.tipsArchiveList li {
  min-height: 210px;
  background: url(../img/tips/bg_tips-item03.png) no-repeat left center, url(../img/tips/bg_tips-item01.png) no-repeat center;
  background-size: 29px 107px, cover;
}

.tipsArchiveList a {
  display: block;
  height: 100%;
  width: 100%;
  padding: 28px 20px 22px 48px;
}

.orangeTipArticle {
  margin-top: 25px;
}

.orangeTipArticle .articleTitle {
  padding: 22px 22px 22px 43px;
  background: url(../img/tips/bg_tips-item02.png) no-repeat left center, url(../img/tips/bg_tips-item01.jpg) no-repeat center;
  background-size: 32px 27px, cover;
  font-size: 14px;
  letter-spacing: 0.1em;
}

.orangeTipArticle .articleContent {
  margin-top: 25px;
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0.05em;
}

.orangeTipArticle .articleContent > * + * {
  margin-top: 15px;
}

.orangeTipArticle .articleContent hr {
  margin: 30px 0;
  border: solid 2px #f0ede6;
}

.orangeTipArticle .articlePicture {
  text-align: center;
}

.orangeTipArticle .articlePicture figure {
  display: inline-block;
  max-width: 682px;
}

.orangeTipArticle .articlePicture figcaption {
  margin-top: 15px;
  font-size: 12px;
  line-height: 1.91;
  letter-spacing: 0.05em;
}

.articlePagination {
  margin-top: 42px;
  padding-top: 42px;
  height: 82px;
  text-align: center;
  position: relative;
}

.articlePagination .next,
.articlePagination .prev {
  position: absolute;
  top: 0;
}

.articlePagination .next img,
.articlePagination .prev img {
  height: 22px;
  width: auto;
}

.articlePagination .next {
  right: 0;
}

.articlePagination .prev {
  left: 0;
}

.articlePagination .backToIndex {
  display: inline-block;
  width: 182px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
  position: relative;
}

.articlePagination .backToIndex:after {
  content: "";
  height: 12px;
  width: 182px;
  background: url(../img/tips/bg_back-to-index.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 27px;
  left: 0;
}

.tipsArchivePagination {
  margin-top: 36px;
  height: 82px;
  font-family: 'Cabin Sketch', cursive;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.tipsArchivePagination .page,
.tipsArchivePagination .current {
  display: inline-block;
  width: 35px;
  font-size: 18px;
}

.tipsArchivePagination .current {
  position: relative;
}

.tipsArchivePagination .current:after {
  content: "";
  margin: auto;
  height: 16px;
  width: 35px;
  background: url(../img/tips/ico_current.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: -17px;
  left: 0;
}

.tipsArchivePagination .previouspostslink,
.tipsArchivePagination .nextpostslink {
  font-size: 16px;
  letter-spacing: 0.1em;
  position: absolute;
  bottom: 0;
}

.tipsArchivePagination .previouspostslink:before,
.tipsArchivePagination .nextpostslink:before {
  content: "";
  height: 22px;
  width: 13px;
  background: no-repeat center;
  background-size: contain;
  position: absolute;
  top: 0;
}

.tipsArchivePagination .page + .page,
.tipsArchivePagination .current + .page,
.tipsArchivePagination .page + .current {
  margin-left: 20px;
}

.tipsArchivePagination .previouspostslink {
  padding-left: 30px;
  left: 0;
}

.tipsArchivePagination .previouspostslink:before {
  background-image: url(../img/common/ico_prev-black.svg);
  left: 0;
}

.tipsArchivePagination .nextpostslink {
  padding-right: 30px;
  right: 0;
}

.tipsArchivePagination .nextpostslink:before {
  background-image: url(../img/common/ico_next-black.svg);
  right: 0;
}

.topIntroPicture,
.topIntroCatch,
.topIntroText {
  -webkit-transition: opacity .5s ease, -webkit-transform .5s ease;
  transition: opacity .5s ease, -webkit-transform .5s ease;
  transition: opacity .5s ease, transform .5s ease;
  transition: opacity .5s ease, transform .5s ease, -webkit-transform .5s ease;
  opacity: 0;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}

.topIntroPicture[data-emergence="visible"],
.topIntroCatch[data-emergence="visible"],
.topIntroText[data-emergence="visible"] {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

@media screen and (max-width: 767px) {
  #wrap {
    height: 100%;
    overflow: hidden;
  }
  .footer .contents {
    margin-top: 38px;
  }
  .citrusSeason {
    margin-top: 14px;
  }
  .orangeTypesList {
    margin-top: 35px;
  }
  .orangeTypesList li {
    height: 168px;
    width: calc(50% - 2px);
    position: relative;
  }
  .orangeTypesList li:nth-of-type(2n):before {
    content: "";
    margin-left: 2px;
    width: 2px;
    background-color: #016411;
    position: absolute;
    top: 10px;
    left: -1px;
    bottom: 10px;
  }
  .orangeTypesList li:nth-of-type(2n + 1):not(:first-of-type):before {
    content: "";
    margin-left: 2px;
    height: 3px;
    width: calc(200%);
    background-color: #016411;
    z-index: 1;
    position: absolute;
    top: -1.5px;
    left: 0;
  }
  .citrusDl01 dd,
  .citrusDl02 dd {
    margin-top: 14px;
  }
  .citrusDl02 dt:nth-of-type(n + 2) {
    margin-top: 18px;
  }
  .citrusIngredientList dt:nth-of-type(n + 2), .citrusIngredientList dd:nth-of-type(n + 2) {
    margin-top: 10px;
  }
  .orangeTipsList li + li {
    margin-top: 10px;
  }
  .tipsArchiveList li + li {
    margin-top: 20px;
  }
}

@media screen and (min-width: 834px), print {
  #wrap {
    min-width: 1288px;
    max-width: 1346px;
    margin: 0 auto;
    padding: 20px 56px 0 20px;
  }
  #wrap:before, #wrap:after {
    content: "";
    width: 28px;
    position: absolute;
    right: 56px;
  }
  #wrap:after {
    background: url(../img/common/bg_header02_pc.png) repeat-y left top;
    background-size: 100% auto;
    top: 25px;
    bottom: 99px;
  }
  #wrap:before {
    height: 10px;
    background: url(../img/common/bg_header01_pc.png) no-repeat left top;
    top: 15px;
  }
  .mainContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 40px auto 0;
    width: 1173px;
  }
  .mainContainer .sideNav {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 50px;
    width: 280px;
  }
  .mainContainer .mainContents {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .sideOrangesHead {
    margin: 40px auto 0;
    height: 54px;
    line-height: 54px;
    width: 260px;
    background: url(../img/common/bg_orange-types.png) no-repeat center;
    background-size: cover;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
  }
  .sideOranges {
    margin: 23px auto 0;
    width: 260px;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05em;
  }
  .sideOranges li {
    -webkit-transition: transform .5s ease;
    transition: transform .5s ease;
  }
  .sideOranges li:hover {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  .sideOranges img {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }
  .sideOranges li + li {
    margin-top: 20px;
  }
  .button {
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
  }
  .button:hover {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  .topIntroPicture {
    margin-top: 82px;
    width: 539px;
  }
  .topIntroCatch {
    margin-top: 38px;
    font-size: 26px;
  }
  .topIntroCatch span:before {
    height: 96px;
    width: 534px;
    background-image: url(../img/top/bg_intro-catch_pc.png);
    position: absolute;
    top: 39px;
    left: -159px;
  }
  .topIntroCatch span:after {
    content: "";
    height: 152px;
    width: 941px;
    background: url(../img/top/img_intro-clouds.png) no-repeat center;
    background-size: contain;
    position: absolute;
    top: -90px;
    left: -350px;
  }
  .topIntroText {
    margin-top: 48px;
    text-align: center;
    line-height: 2.46;
    letter-spacing: 0.1em;
  }
  .orangeTypes {
    margin: 68px auto 0;
    padding: 0 61px 69px 61px;
    width: 1060px;
  }
  .orangeTypes:before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .orangeTypes:after {
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
  }
  .orangeTypes .decoration:before {
    height: 268px;
    width: 308px;
    background-image: url(../img/types/bg_top-left_pc.png);
    top: -31px;
    left: -28px;
  }
  .orangeTypes .decoration:after {
    height: 288px;
    width: 303px;
    background-image: url(../img/types/bg_top-right_pc.png);
    top: -34px;
    right: -28px;
  }
  .orangeTypesList {
    margin-top: 88px;
  }
  .orangeTypesList li {
    height: 195px;
    width: calc(100% / 5);
  }
  .orangeTypesList li + li:not(:nth-of-type(5n + 1)):before {
    content: "";
    height: 175px;
    width: 2px;
    background: #0e6613;
    z-index: 1;
    position: absolute;
    top: 10px;
    left: -1px;
  }
  .orangeTypesList li:nth-of-type(5n + 1):not(:first-of-type):before {
    content: "";
    height: 3px;
    width: 938px;
    background: #0e6613;
    z-index: 1;
    position: absolute;
    top: -1.5px;
  }
  .orangeTypesList a:after {
    content: "";
    margin: auto;
    height: 88px;
    width: 81px;
    background: url(../img/types/ico_more.png) no-repeat center;
    background-size: contain;
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: opacity .5s ease, -webkit-transform .5s ease;
    transition: opacity .5s ease, -webkit-transform .5s ease;
    transition: transform .5s ease, opacity .5s ease;
    transition: transform .5s ease, opacity .5s ease, -webkit-transform .5s ease;
    position: absolute;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .orangeTypesList a:hover:after {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  .orangeTypesTitle {
    font-size: 28px;
  }
  .orangeTypesTitle span:before {
    height: 156px;
    width: 191px;
    background-image: url(../img/top/img_types-title_pc.png);
    top: -102px;
    left: -5px;
  }
  .citrusCalendarList li {
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 134px;
  }
  .citrusCalendarList li:after {
    bottom: 0;
  }
  .citrusCalendarList dl {
    padding-right: 40px;
    width: 342px;
  }
  .citrusCalendarList dt {
    margin: 15px 0px 0 8px;
  }
  .citrusCalendarList dt img {
    margin-right: 18px;
  }
  .citrusCalendarList dd {
    line-height: 2;
  }
  .citrusSeason {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 400px;
  }
  .citrusSeason .label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 11px;
    line-height: 1.27;
    letter-spacing: 0.05em;
  }
  .citrusIngredientList {
    width: 843px;
    padding: 50px;
  }
  .citrusIngredientList dt, .citrusIngredientList dd {
    padding: 0;
    height: 50px;
    line-height: 50px;
  }
  .citrusIngredientList dt:nth-of-type(n + 3), .citrusIngredientList dd:nth-of-type(n + 3) {
    margin-top: 10px;
  }
  .citrusIngredientList dt {
    width: 180px;
    background-image: url(../img/types/bg_ingredient01.jpg);
  }
  .citrusIngredientList dt:nth-of-type(2n) {
    margin-left: 10px;
  }
  .citrusIngredientList dt:nth-of-type(4n - 1), .citrusIngredientList dt:nth-of-type(4n - 2) {
    background-image: url(../img/types/bg_ingredient02.jpg);
  }
  .citrusIngredientList dd {
    padding-left: 24px;
    width: 186px;
  }
  .citrusCalendar {
    margin: 93px 0 0;
    padding: 30px 50px 60px;
    background-image: url(../img/types/bg_calendar_pc.jpg);
    position: relative;
  }
  .citrusCalendarList {
    height: auto !important;
  }
  .citrusCalendarList .citrus01 .citrusSeason .label {
    width: 120px;
  }
  .citrusCalendarList .citrus02 .citrusSeason .label {
    width: 60px;
  }
  .citrusCalendarList .citrus03 .citrusSeason .label {
    width: 200px;
  }
  .citrusCalendarList .citrus04 .citrusSeason .label {
    margin-left: 60px;
    width: 80px;
  }
  .citrusCalendarList .citrus05 .citrusSeason .label {
    margin-left: 60px;
    width: 80px;
  }
  .citrusCalendarList .citrus06 .citrusSeason .label {
    margin-left: 70px;
    width: 130px;
  }
  .citrusCalendarList .citrus07 .citrusSeason .label {
    margin-left: 100px;
    width: 160px;
  }
  .citrusCalendarList .citrus08 .citrusSeason .label {
    margin-left: 80px;
    width: 80px;
  }
  .citrusCalendarList .citrus09 .citrusSeason .label {
    margin-left: 80px;
    width: 90px;
  }
  .citrusCalendarList .citrus10 .citrusSeason .label {
    margin-left: 80px;
    width: 120px;
  }
  .citrusCalendarList .citrus11 .citrusSeason .label {
    margin-left: 80px;
    width: 200px;
  }
  .citrusCalendarList .citrus12 .citrusSeason .label {
    margin-left: 100px;
    width: 70px;
  }
  .citrusCalendarList .citrus13 .citrusSeason .label {
    margin-left: 120px;
    width: 100px;
  }
  .citrusCalendarList .citrus14 .citrusSeason .label {
    margin-left: 140px;
    width: 80px;
  }
  .citrusCalendarList .citrus15 .citrusSeason .label {
    margin-left: 160px;
    width: 60px;
  }
  .citrusCalendarList .citrus16 .citrusSeason .label {
    margin-left: 190px;
    height: 50px;
    width: 50px;
  }
  .citrusCalendarList .citrus17 .citrusSeason .label {
    margin-left: 190px;
    height: 50px;
    width: 50px;
  }
  .citrusCalendarList .citrus18 .citrusSeason .label {
    margin-left: 180px;
    width: 120px;
  }
  .citrusCalendarList .citrus19 .citrusSeason .label {
    margin-left: 260px;
    width: 80px;
  }
  .citrusCalendarList .citrus20 .citrusSeason .label {
    margin-left: 340px;
    width: 60px;
  }
  .citrusSeason .text {
    display: none;
  }
  .citrusDl01 dd {
    margin-top: 14px;
  }
  .citrusDl02 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .citrusDl02 dt:nth-of-type(n + 2), .citrusDl02 dd:nth-of-type(n + 2) {
    margin-top: 30px;
  }
  .citrusDl02 dt {
    margin-right: 30px;
    height: auto;
    width: 220px;
  }
  .citrusDl02 dd {
    width: 593px;
  }
  .citrusCalendarHeading h2 {
    font-size: 28px;
  }
  .citrusCalendarHeading:after {
    height: 95px;
    width: 79px;
  }
  .citrusCalendarHeading:after {
    bottom: -97px;
    left: 150px;
  }
  .citrusHeading span {
    font-size: 20px;
  }
  .citrusHeading h1 {
    font-size: 28px;
  }
  .citrusHeading:before {
    height: 234px;
    width: 204px;
    background-image: url(../img/types/bg_top-left02_pc.png);
    left: 0;
  }
  .citrusHeading:after {
    height: 219px;
    width: 231px;
    background-image: url(../img/types/bg_top-right02_pc.png);
    right: 0;
  }
  .citrusData {
    margin-bottom: 82px;
  }
  .citrusCalendarScale {
    position: absolute;
    top: -46px;
    right: 65px;
  }
  .citrusRecipeHeading {
    font-size: 26px;
  }
  .citrusRecipeHeading .inner {
    display: inline-block;
    width: 398px;
    padding-left: 5px;
    text-align: left;
  }
  .citrusRecipeHeading:after {
    height: 43px;
    width: 398px;
    background-image: url(../img/types/bg_recipe-text_pc.png);
    bottom: -12px;
    left: 0;
  }
  .citrusRecipe {
    margin-top: 50px;
    text-align: center;
  }
  .orangeTips {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 105px auto 0;
    width: 1060px;
  }
  .orangeTipsTitle {
    margin-right: 42px;
    padding: 57px 0 0 41px;
    height: 300px;
    width: 329px;
    background-image: url(../img/top/bg_tips_pc.png);
    background-size: contain;
  }
  .orangeTipsTitle:before {
    left: 10px;
    right: auto;
  }
  .orangeTipsTitle .heading {
    font-size: 26px;
  }
  .orangeTipsTitle .heading:before {
    bottom: -49px;
  }
  .orangeTipsTitle .more {
    font-size: 14px;
    bottom: 112px;
    left: 67px;
    transition: transform .5s ease;
  }
  .orangeTipsTitle .more:hover {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  .orangeTipsList {
    -ms-flex-item-align: start;
    align-self: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 689px;
  }
  .orangeTipsList li {
    height: 75px;
    width: 330px;
  }
  .orangeTipsList li:nth-of-type(2n) {
    margin-left: 29px;
  }
  .orangeTipsList li:nth-of-type(n + 3) {
    margin-top: 30px;
  }
  .orangeTipsList a {
    padding-left: 48px;
    padding-right: 1em;
  }
  .tipsArchiveList {
    width: 843px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .tipsArchiveList li {
    min-height: 185px;
    width: 406px;
  }
  .tipsArchiveList li:nth-of-type(2n) {
    margin-left: 30px;
  }
  .tipsArchiveList li:nth-of-type(n + 3) {
    margin-top: 30px;
  }
  .articlePagination {
    margin-top: 62px;
    padding: 0;
  }
  .articlePagination .prev,
  .articlePagination .next {
    top: 12px;
  }
  .tipsArchivePagination {
    margin-top: 75px;
    height: auto;
  }
  .tipsArchivePagination .page,
  .tipsArchivePagination .current {
    font-size: 20px;
  }
  .tipsArchivePagination .page + .page,
  .tipsArchivePagination .current + .page,
  .tipsArchivePagination .page + .current {
    margin-left: 26px;
  }
  .orangeTipsList li,
  .sideTipsList li,
  .tipsArchiveList li {
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
  }
  .orangeTipsList li:hover,
  .sideTipsList li:hover,
  .tipsArchiveList li:hover {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

.orangeTipsList span {
  display: block;
  width: 100%;
}

#wpadminbar {
  top: auto !important;
  bottom: 0;
}

#wpadminbar .ab-sub-wrapper {
  bottom: 100%;
}

.orangeTypesList {
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .orangeTypesList {
    height: auto !important;
  }
}

@media screen and (max-width: 767px) {
  #wpadminbar {
    display: none !important;
  }
}

@media screen and (max-width: 375px) {
  .toggleMenu {
    width: 51.73vw;
  }
}

@media screen and (max-width: 767px) {
  .footer .onlineShops li,
  .overlayMenu .onlineShops li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
  }
  .footer .onlineShops li + li,
  .overlayMenu .onlineShops li + li {
    margin-left: 10px;
  }
  .footer .onlineShops .button,
  .overlayMenu .onlineShops .button {
    width: 100%;
    text-align: center;
  }
  .footer .onlineShops .onlineShopsList,
  .overlayMenu .onlineShops .onlineShopsList {
    padding-left: 22px;
    padding-right: 22px;
  }
  .orangeTypesList {
    -webkit-transition: height .8s ease;
    transition: height .8s ease;
  }
}

.decoBorder {
  /* height: 100%; */
  /* width: 10px; */
  /* background: url(../img/common/bg.jpg) repeat left top; */
  /* background-size: 100% auto; */
  /* background-size: 100vw auto; */
  /* z-index: 9999; */
  /* position: fixed; */
  /* top: 0; */
  left: 0;
}

.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.mvTop,
.mvBottom,
.mvOranges,
.mvGirl,
.mvSupervised {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mvTop {
  background: url(../img/top/mv_top.png) no-repeat center top;
  background-size: 100% auto;
  z-index: 1;
}

.mvLogo {
  margin-top: 132px;
  margin-top: 35.2vw;
}

.mvLogo strong {
  margin-top: 12px;
  margin-top: 3.2vw;
  display: block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
}

.mvSupervised {
  background: url(../img/top/mv_supervised.png) no-repeat left top;
  background-size: 100% auto;
  z-index: 3;
}

.mvGirl {
  z-index: 1;
  background: url(../img/top/mv_girl.png) no-repeat center bottom 0;
  background-size: 100% auto;
}

.mvOranges {
  background: url(../img/top/mv_oranges.png) no-repeat center bottom 0;
  background-size: 100% auto;
}

.mvBottom {
  background: url(../img/top/mv_bottom.png) no-repeat center bottom 0;
  background-size: 100% auto;
}

.mvScroll {
  margin: auto;
  height: 30px;
  width: 90px;
  background-color: #fff;
  z-index: 5;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.mvScroll img {
  display: block;
  margin: 0 auto;
  width: 55px;
}

.mvScroll:after {
  content: "";
  margin: auto;
  height: 12px;
  width: 2px;
  background-color: #016411;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

@media screen and (min-width: 834px) {
  .mainVisual {
    height: calc(100vh - 20px);
  }
  .mvTop {
    background-image: url(../img/top/mv_top_pc.png);
  }
  .mvGirl {
    background-image: url(../img/top/mv_girl_pc.png);
  }
  .mvOranges {
    background-image: url(../img/top/mv_oranges_pc.png);
  }
  .mvBottom {
    background-image: url(../img/top/mv_bottom_pc.png);
  }
  .mvLogo {
    margin-top: 184px;
    margin-top: 23.9583vh;
  }
  .mvLogo strong {
    margin-top: 15px;
    font-size: 14px;
  }
  .mvSupervised {
    margin-top: 244px;
    margin-top: 31.77vh;
    background-image: url(../img/top/mv_supervised_pc.png);
  }
  .mvScroll {
    height: 63px;
    width: 102px;
  }
  .mvScroll:after {
    height: 35px;
  }
}

@media screen and (max-width: 375px) {
  .mvLogo {
    margin: 0 auto;
    width: 90%;
    margin-top: 32.8125vw;
  }
  .citrusCalendarHeading h2 {
    font-size: 6.4vw;
  }
}

@media screen and (orientation: landscape) and (max-width: 767px) {
  .mainVisual {
    height: calc(100vh - 72px);
  }
  .mvTop {
    background-image: url(../img/top/mv_top_pc.png);
  }
  .mvGirl {
    background-image: url(../img/top/mv_girl_pc.png);
  }
  .mvOranges {
    background-image: url(../img/top/mv_oranges_pc.png);
  }
  .mvBottom {
    background-image: url(../img/top/mv_bottom_pc.png);
  }
  .mvLogo {
    width: 50%;
    margin: 184px auto 0;
    margin: 23.9583vh auto 0;
  }
  .mvLogo strong {
    margin-top: 15px;
    font-size: 14px;
  }
  .mvSupervised {
    margin-top: 122px;
    margin-top: 15.88vh;
    background-image: url(../img/top/mv_supervised_pc.png);
  }
}

.orangeIcon {
  display: inline-block;
  height: 23px;
  width: 35px;
}

.notFound {
  padding-top: 150px;
  padding-top: 40vw;
  color: #0e6613;
  font-weight: bold;
  position: relative;
}

.notFound .heading {
  text-align: center;
}

.notFound .text1 {
  margin-top: 28px;
  margin-top: 7.46vw;
  display: block;
  font-size: 17px;
  text-align: center;
  line-height: 1.82;
  letter-spacing: 0.1em;
}

.notFound .text2 {
  margin-top: 21px;
  margin-top: 5.6vw;
  font-size: 13px;
  line-height: 2.15;
  letter-spacing: 0.05em;
}

.notFound:before, .notFound:after {
  content: "";
  background: no-repeat center;
  background-size: contain;
  position: absolute;
}

.notFound:before {
  height: 192px;
  height: 51.2vw;
  width: 130px;
  width: 34.66vw;
  background-image: url(../img/404/bg_top-left.png);
  top: 14px;
  left: -12px;
}

.notFound:after {
  height: 203px;
  height: 54.13vw;
  width: 152px;
  width: 40.53vw;
  background-image: url(../img/404/bg_top-right.png);
  top: 16px;
  right: -11px;
}

.buttonWrapper {
  position: relative;
}

.notFound .button {
  display: flex;
  margin: 100px auto 0;
  height: 60px;
  font-size: 13px;
  letter-spacing: 0.1em;
  max-width: 325px;
  color: #fff;
  background: url(../img/common/ico_next-white.svg) no-repeat right 13px center, url(../img/404/bg_btn.jpg) no-repeat center;
  background-size: auto 22px, cover;
}

.notFound .buttonGirl {
  height: 106px;
  width: 99px;
  background: url(../img/404/img_404-girl.png) no-repeat center;
  background-size: contain;
  margin: auto;
  -webkit-transition: transform .5s ease;
  transition: transform .5s ease;
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
  position: absolute;
  left: 0;
  bottom: 100%;
  right: 0;
}

@media screen and (max-width: 767px) {
  .notFound .heading img {
    height: 36px;
    height: 9.6vw;
    width: auto;
  }
}

@media screen and (min-width: 768px), print {
  .mainContainer.errorPage .sideNav {
    margin-right: 35px;
  }
  .notFound {
    width: 843px;
    padding-top: 130px;
  }
  .notFound .text1 {
    margin-top: 50px;
    font-size: 20px;
  }
  .notFound .text2 {
    text-align: center;
    line-height: 2.46;
    letter-spacing: 0.1em;
  }
  .notFound:before {
    height: 204px;
    width: 234px;
    background-image: url(../img/404/bg_top-left_pc.png);
    top: -2px;
    left: 0;
  }
  .notFound:after {
    height: 219px;
    width: 231px;
    background-image: url(../img/404/bg_top-right_pc.png);
    top: -4px;
    right: 0;
  }
  .notFound .button {
    margin-top: 111px;
    height: 54px;
    width: 310px;
    font-size: 14px;
  }
  .notFound .buttonWrapper .button:hover + .buttonGirl {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: currentColor;
  text-decoration: none;
}

img {
  max-width: 100%;
  border: none;
  vertical-align: top;
}

ul,
ol {
  list-style: none;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

@media (max-width: 835px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 835px) {
  .smOnly {
    display: none !important;
  }
}

.fixed_cont_menu {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(58, 188, 174, 0.7);
  padding: 10px;
  z-index: 1;
  border-top: 5px solid #b0ca71;
}

.fixed_cont_menu .pccont_btn_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

@media only screen and (max-width: 834px) {
  .fixed_cont_menu .pccont_btn_wrap {
    padding: 0;
  }
}

.fixed_cont_menu .pccont_btn_wrap li {
  margin: 0 5px;
}

@media only screen and (max-width: 834px) {
  .fixed_cont_menu .pccont_btn_wrap li {
    margin: 0 5px;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
  background-size: cover;
}

footer#global_footer .inner {
  padding: 60px 10px 50px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 10px;
  }
}

footer#global_footer a {
  text-decoration: none;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  footer#global_footer #nav_footer {
    margin-bottom: 15px;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

footer#global_footer #nav_footer li {
  text-align: left;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 2px 10px;
  display: block;
  color: #333333;
  font-weight: bold;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy {
  color: #ffffff;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.small_bg {
  background: #69821b;
}

.ftwrap2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .ftwrap2 {
    justify-content: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .ftwrap2 {
    display: block;
  }
}

.ftcont {
  max-width: 306px;
  margin: 0 auto;
}

.ftcont a {
  background: #dc4c35;
  color: #ffffff;
  font-size: 1.25em;
  padding: 20px 30px;
  display: block;
  height: 100%;
  border-radius: 10px;
  text-align: left;
  font-weight: bold;
}

.fttelwrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .fttelwrap {
    display: block;
  }
}

.ft2_allwrap {
  padding: 60px 0;
  background: url(../images/common/cont_bg.jpg) center no-repeat;
  background-size: cover;
}

.footer2 {
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 834px) {
  .footer2 {
    background-size: cover;
    padding: 60px 10px;
  }
}

.footer2 .time {
  margin-top: 40px;
}

.footer2 .inner {
  padding: 0;
}

.footer2 .fth2 {
  margin-bottom: 50px;
}

.footer2 .fth2 h2 {
  font-size: 2em;
  position: relative;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  padding: 5px 0;
  line-height: 1;
}

@media only screen and (max-width: 834px) {
  .footer2 .fth2 h2 {
    font-size: 1.8em;
    background-size: contain;
  }
}

.footer2 .ft_txt {
  color: #ffffff;
  margin-bottom: 30px;
}

.footer2 .telwrap {
  background: url(../images/common/orange_pt.png);
  padding: 10px;
  margin-right: 30px;
}

@media only screen and (max-width: 640px) {
  .footer2 .telwrap {
    margin-right: 0;
  }
}

.footer2 .telwrap a {
  color: #333333;
}

.footer2 .telwrap .fttime {
  margin-top: 5px;
  font-size: 13px;
  line-height: 1.5em;
  background: #fff;
  border-radius: 50px;
  padding: 5px;
}

@media only screen and (max-width: 640px) {
  .footer2 .telwrap {
    display: block;
  }
}

.footer2 .telwrap .tel2 {
  font-size: 1.7em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  color: #ffffff;
}

.footer2 .telwrap .tel2 a {
  color: #ffffff;
  font-weight: bold;
}

.footer2 .telwrap .tel2 img {
  max-width: calc(56px / 2);
  margin-right: 7px;
}

.footer2 .ft_cont a {
  background: url(../images/common/blue_pt.png);
  color: #ffffff;
  font-size: 1.3em;
  padding: 36px 22px;
  display: block;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  height: 100%;
}

.footer2 .ft_cont a img {
  max-width: calc(56px / 2);
  margin-right: 7px;
  vertical-align: middle;
}

#copy {
  color: #333;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 15%;
  z-index: 2;
}

.pagetop a {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  background: #dc4c35;
  border-radius: 100%;
  display: block;
  height: 60px;
  width: 60px;
  padding: 14px;
}

.pagetop img {
  max-width: calc(150px / 2);
}

@media only screen and (max-width: 640px) {
  .pagetop {
    bottom: 18%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .pagetop {
    bottom: 22%;
  }
}

.ft_L {
  text-align: left;
  color: #333333;
  line-height: 1.3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .ft_L {
    text-align: right !important;
    padding: 0 10px;
  }
}

.ft_L .ftlogo {
  margin-right: 50px;
}

.ft_L .address {
  margin-top: 10px;
  font-size: 13px;
}

.ft_L .tel2 a {
  color: #333333;
}

@media only screen and (max-width: 834px) {
  .ft_L {
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .ft_L {
    padding-left: 10px;
  }
}

.ftmap iframe {
  width: 100%;
  height: 200px;
}

/* box */
div.items {
  flex-wrap: wrap;
}

div.items > article {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  width: 30% !important;
}

@media only screen and (max-width: 640px) {
  div.items > article {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  div.items > article {
    width: 48% !important;
  }
}

div.items > article a.photo {
  width: 100% !important;
}

.under ul li a {
  background: #dc4c35;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #dc4c35 !important;
}

.under ul li a:hover {
  border: 1px solid #595959 !important;
}

#shop_h2 {
  font-size: 2em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  background: #b0ca71;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin-top: 30px;
}

@media only screen and (max-width: 834px) {
  #shop_h2 {
    font-size: 1.5em;
  }
}

.h2_01 h2 {
  font-size: 2em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  background: linear-gradient(transparent 45%, #fbee1a 45%);
  max-width: 874px;
  margin: 0 auto;
  line-height: 1em;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 1.7em;
  }
}

.h2_02 h2 {
  font-size: 2em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  line-height: 1em;
  position: relative;
  max-width: 675px;
  margin: 0 auto;
}

.h2_02 h2:before {
  content: url(../images/common/midashi_iconL.png);
  position: absolute;
  left: -66px;
  top: 0;
}

.h2_02 h2:after {
  content: url(../images/common/midashi_iconR.png);
  position: absolute;
  right: -55px;
  top: 0;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 1.5em;
    background-size: contain;
  }
}

.h2_03 h2 {
  font-size: 2em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  line-height: 1em;
}

.h2_03 h2:before {
  content: url(../images/common/cloud_icon.png);
  display: block;
  padding-bottom: 10px;
}

@media only screen and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 1.5em;
    background-size: contain;
  }
}

.h2_04 h2 {
  font-size: 2em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  line-height: 1em;
}

.h2_04 h2:before {
  content: url(../images/common/web_icon.png);
  display: block;
  padding-bottom: 10px;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 {
    font-size: 1.5em;
    background-size: contain;
  }
}

.h2_05 h2 {
  font-size: 2em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  line-height: 1em;
}

.h2_05 h2:before {
  content: url(../images/common/xmobile_icon.png);
  display: block;
  padding-bottom: 10px;
}

@media only screen and (max-width: 834px) {
  .h2_05 h2 {
    font-size: 1.5em;
    background-size: contain;
  }
}

.h2_06 h2 {
  font-size: 2em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  line-height: 1em;
}

.h2_06 h2 span {
  color: #ea8811;
}

@media only screen and (max-width: 834px) {
  .h2_06 h2 {
    font-size: 1.5em;
    background-size: contain;
  }
}

.txt {
  line-height: 2em;
}

@media only screen and (max-width: 640px) {
  .top_txt div {
    text-align: left !important;
  }
}

.btn {
  max-width: 230px;
  margin: 0 auto;
}

.btn a {
  display: block;
  width: 100%;
  padding: 10px 0;
  color: #69821b;
  max-width: 230px;
  margin: 0 auto;
  background: #69821b;
  color: #ffffff;
  position: relative;
  border-radius: 10px;
}

.btn a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 10px;
  /*アイコンの位置*/
  top: 10px;
  /*アイコンの位置*/
  color: #ffffff;
  /*アイコン色*/
}

.itembox {
  align-items: center;
}

.itembox .box {
  background: #fff;
  padding: 10px;
  border-bottom: 2px solid #dc4c35;
}

@media only screen and (max-width: 640px) {
  .itembox .box {
    height: 400px;
  }
}

.itembox .box .price {
  font-size: 1.5em;
  font-weight: bold;
  text-align: right;
  max-width: 285px;
  display: block;
}

.itembox .box img {
  height: 230px !important;
  width: 230px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  margin: 0 auto;
}

.itembox .box div {
  font-size: 13px;
}

.sec_01 {
  background: rgba(255, 255, 255, 0.8);
  margin: 50px auto;
  padding: 100px 0 100px 0;
  max-width: 1000px;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sec_02 .flxL {
  width: 48%;
}

.sec_02 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block !important;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
    margin-top: 10px;
  }
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.sec_03 .flxL {
  width: 48%;
}

.sec_03 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block !important;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
    margin-top: 10px;
  }
}

.td_01 td {
  background: #fff !important;
  border: none !important;
  font-size: 18px;
}

@media only screen and (max-width: 640px) {
  .td_01 td {
    font-size: 13px;
  }
}

.td_01 td {
  border-bottom: 1px solid #ccc !important;
}

.td_01 td div {
  font-weight: bold;
}

.td_01 .td_top {
  background: #C1D615 !important;
  color: #ffffff;
}

.td_01 .td_side {
  background: #f7f7f7 !important;
}

.td_01 .td_txt .small {
  font-size: 13px;
}

.td_01 .td_txt .big {
  font-size: 1.25em;
}

.instagram .sns_text {
  display: none;
}

.instagram div.sns_list {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  border: none !important;
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list {
    justify-content: flex-start;
  }
}

.instagram div.sns_list > div {
  width: 20% !important;
}

@media only screen and (max-width: 640px) {
  .instagram div.sns_list > div {
    width: 48% !important;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list > div {
    width: 33% !important;
  }
}

.instagram div.sns_list div.sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

.instagram div.sns_list > div {
  border-bottom: none !important;
}

.instagram .sns_photo img {
  width: 240px !important;
  height: 240px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram .sns_photo img {
    height: 100% !important;
    width: 100% !important;
  }
}

.bnrbox {
  justify-content: space-between;
}

.bnrbox .box {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
    margin: 5px;
  }
}

.bnrbox h3 {
  display: none;
}

.bnrbox2 {
  justify-content: space-between;
}

.bnrbox2 .box {
  width: 23%;
}

@media only screen and (max-width: 640px) {
  .bnrbox2 .box {
    width: 48%;
    margin: 5px;
  }
}

.bnrbox2 h3 {
  display: none;
}

.bnrbox3 {
  justify-content: space-between;
}

.bnrbox3 .box {
  width: 31%;
}

@media only screen and (max-width: 640px) {
  .bnrbox3 .box {
    width: 100%;
    margin: 5px;
  }
}

.bnrbox3 h3 {
  display: none;
}

.img-animation {
  overflow: hidden;
  position: relative;
}

.img-animation.animated:after {
  -webkit-animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  background: #fff;
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@-webkit-keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.newswrap .news {
  padding-left: 30px;
  height: 200px;
  overflow-y: auto;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    margin-top: 30px;
    padding-left: 0;
  }
}

.newswrap .news dt {
  border: none !important;
  background-color: #69821b !important;
  color: #ffffff;
  text-align: center !important;
  display: block !important;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .newswrap .news dt {
    width: 100% !important;
  }
}

.newswrap .news dd {
  border: none !important;
}

@media only screen and (max-width: 834px) {
  .newswrap .news dd {
    width: 100% !important;
  }
}

.newswrap .news dl {
  padding: 10px 0 !important;
}

@media only screen and (max-width: 834px) {
  .newswrap .news dl {
    display: block !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

div.blog_list div.blog_photo {
  width: 100% !important;
  padding: 0 !important;
}

div.blog_list div.blog_photo img {
  width: 280px !important;
  height: 180px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 640px) {
  div.blog_list div.blog_photo img {
    width: 100% !important;
  }
}

div.blog_list {
  flex-direction: inherit !important;
  border: none !important;
  justify-content: center;
  font-size: 13px;
}

@media only screen and (max-width: 640px) {
  div.blog_list {
    display: block;
  }
}

div.blog_list a {
  color: #ffffff !important;
  font-weight: bold;
}

div.blog_list a:hover {
  text-decoration: underline;
}

div.blog_list > div {
  display: block !important;
  padding: 10px;
  margin: 5px;
  border-bottom: none !important;
  width: 31%;
}

@media only screen and (max-width: 640px) {
  div.blog_list > div {
    width: 100% !important;
    margin: 10px 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  div.blog_list > div {
    width: 48% !important;
  }
}

.blog_date {
  color: #333333 !important;
  text-align: left;
}

.blog_text {
  font-size: 1em !important;
  width: 100% !important;
  padding: 0 !important;
}

div.blog_list h3 {
  font-size: 13px !important;
  text-align: left;
  margin-bottom: 0 !important;
  font-weight: bold;
  background: #b0ca71;
  padding: 5px;
}

div.detail {
  margin-top: 10px !important;
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.dl_txt {
  background: #f7f7f7;
  padding: 10px;
}

.dl_txt dt {
  border: none !important;
  display: block !important;
  width: 100% !important;
  border-bottom: 1px solid #b0ca71 !important;
  font-weight: bold;
}

.dl_txt dd {
  width: 100% !important;
  border: none !important;
}

.dl_txt dl {
  display: block !important;
}

.dl_txt .btn {
  text-align: center;
  margin-top: 15px;
}

.dl_txt .btn a {
  background: #dc4c35;
}

.h2_07 h2 {
  font-size: 2em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  background: #fff;
  padding: 15px;
  border-left: 7px solid #dc4c35;
  border-bottom: 1px solid #ccc;
}

@media only screen and (max-width: 834px) {
  .h2_07 h2 {
    font-size: 1.5em;
  }
}

.h2_08 h2 {
  font-size: 1.7em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

.h2_08 h2 span {
  font-size: 40px;
}

@media only screen and (max-width: 834px) {
  .h2_08 h2 {
    font-size: 1.5em;
  }
}

.h2_09 h2 {
  font-size: 2em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  background: #b0ca71;
  padding: 10px;
  border-radius: 10px;
}

@media only screen and (max-width: 834px) {
  .h2_09 h2 {
    font-size: 1.5em;
  }
}

#damage {
  margin-top: -60px;
  padding-top: 60px;
}

#anc01 {
  margin-top: -60px;
  padding-top: 60px;
}

#anc02 {
  margin-top: -60px;
  padding-top: 60px;
}

#anc03 {
  margin-top: -60px;
  padding-top: 60px;
}

#sempaku {
  margin-top: -60px;
  padding-top: 60px;
}

.txt div {
  line-height: 2em;
}

.cont_tel .tel2 {
  max-width: 250px;
  margin: 0 auto;
  padding-bottom: 5px;
  font-size: 2em;
  font-family: "M PLUS Rounded 1c";
  font-weight: bold;
}

.cont_tel .tel2 a {
  color: #333333;
  font-weight: bold;
}

.cont_tel .time {
  font-size: 13px;
}

.h3_01 h3 {
  font-size: 1.5em;
  font-weight: bold;
  font-family: "M PLUS Rounded 1c";
  background: #b0ca71;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

@media only screen and (max-width: 640px) {
  .h3_01 h3 {
    font-size: 1.25em;
  }
}

.h3_02 h3 {
  font-size: 1.7em;
  font-weight: bold;
  font-family: "M PLUS Rounded 1c";
  border-bottom: 2px solid #b0ca71;
  padding-bottom: 10px;
}

.h3_02 h3 span {
  display: block;
  font-size: 20px;
  color: #dc4c35;
  padding-bottom: 5px;
}

.h3_03 h3 {
  font-size: 1.5em;
  font-weight: bold;
  font-family: "M PLUS Rounded 1c";
  background: #dc4c35;
  color: #ffffff;
  padding: 10px;
}

.h3_03 h3 span {
  color: #FFF533;
}

.h3_04 h3 {
  font-size: 1.5em;
  font-weight: bold;
  font-family: "M PLUS Rounded 1c";
  background: #f7f7f7;
  color: #333333;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 15px;
}

.h3_04 h3:before {
  content: url(../images/contents/yellow_car.png);
  padding-right: 7px;
}

.h3_04 h3 span {
  color: #FFF533;
}

.qa dt {
  border: none !important;
  width: 100% !important;
  display: block !important;
  border-bottom: 1px dashed #ea8811 !important;
  padding-bottom: 10px !important;
}

.qa dt:before {
  content: 'Q';
  border-radius: 100%;
  padding: 2px 10px;
  background: #ea8811;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.25em;
  width: 37px;
  height: 37px;
  margin-right: 7px;
  display: inline-block;
}

.qa dd {
  border: none !important;
  width: 100% !important;
  padding-top: 10px !important;
}

.qa dd:before {
  content: 'A';
  border-radius: 100%;
  padding: 2px 10px;
  background: #dc4c35;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.25em;
  width: 37px;
  height: 37px;
  margin-right: 7px;
  display: inline-block;
}

.qa dl {
  display: block !important;
  padding: 15px !important;
  background: #fff;
  border-radius: 20px;
  margin: 15px 0;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.dl_01 dt {
  border: none !important;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .dl_01 dt {
    display: block !important;
    width: 100% !important;
  }
}

.dl_01 dd {
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .dl_01 dd {
    width: 100% !important;
  }
}

.dl_01 dl {
  border-bottom: 1px solid #ccc !important;
  padding: 10px 0;
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.td_txt {
  max-width: 600px;
  margin: 0 auto;
}

.td_txt td {
  border: none !important;
  width: 30%;
}

.contbox {
  justify-content: center;
}

.contbox .box {
  width: 30%;
  padding: 15px;
  margin: 10px;
  text-align: center;
  background: #fff;
  border-bottom: 5px solid #dc4c35;
}

@media only screen and (max-width: 640px) {
  .contbox .box {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .contbox .box {
    width: 46% !important;
  }
}

.contbox article div {
  font-weight: bold;
  margin-top: 30px;
}

.contbox article div .big {
  font-size: 1.5em;
}

.contbox article div .small {
  font-size: 13px;
}

.contbox article div .back {
  font-size: 1.5em;
  background: #f7f7f7;
  padding: 5px;
}

.contbox h3 {
  font-weight: bold;
  font-size: 1.25em;
  position: relative;
  color: #dc4c35;
}

.contbox2 {
  justify-content: center;
}

.contbox2 .box {
  width: 30%;
  padding: 15px;
  margin: 10px;
  text-align: center;
  background: #fff;
  border-bottom: 5px solid #dc4c35;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 640px) {
  .contbox2 .box {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .contbox2 .box {
    width: 46% !important;
  }
}

.contbox2 article div {
  font-weight: bold;
  margin-top: 15px;
  text-align: left;
}

.contbox2 article div .big {
  font-size: 1.5em;
}

.contbox2 article div .small {
  font-size: 13px;
}

.contbox2 article div .back {
  font-size: 1.5em;
  background: #f7f7f7;
  padding: 5px;
}

.contbox2 h3 {
  font-weight: bold;
  font-size: 1.25em;
  color: #dc4c35;
}

.contbox3 {
  justify-content: center;
}

.contbox3 .box {
  text-align: center;
  margin: 5px;
  width: 21%;
}

@media only screen and (max-width: 834px) {
  .contbox3 .box {
    width: 46% !important;
  }
}

.contbox3 h3 {
  font-weight: bold;
  border-bottom: 2px solid #b0ca71;
}

.contbox3 article div {
  display: none;
}

.list {
  max-width: 800px;
  margin: 0 auto;
}

.list li {
  padding: 10px;
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 1em;
}

@media only screen and (max-width: 640px) {
  .list li {
    font-size: 1.3em;
  }
}

.list li:before {
  content: url(../images/common/mouse_icon.png);
  margin-right: 15px;
}

.list2 li {
  margin: 5px;
}

@media only screen and (max-width: 640px) {
  .list2 li {
    margin: 5px 10px;
  }
}

.list2 li a {
  background: #dc4c35;
  padding: 15px;
  display: block;
  border-radius: 10px;
  width: 230px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

.list3 {
  font-size: 13px;
}

.list4 li {
  padding-left: 30px;
  position: relative;
  margin: 10px 0;
  font-size: 1.25em;
  font-weight: bold;
}

.list4 li:before {
  content: url(../images/contents/orange_check.png);
  position: absolute;
  left: 0;
}

.itembox2 {
  justify-content: center;
}

.itembox2 .box {
  text-align: center;
  width: 30%;
  margin: 5px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .itembox2 .box {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .itembox2 .box {
    width: 46% !important;
  }
}

.itembox2 .box h3 {
  font-weight: bold;
  margin: 10px 0;
}

.blog_link li {
  background: #dc4c35;
  padding: 15px;
  display: block;
  border-radius: 10px;
  width: 46%;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  margin: 5px 10px;
}

.blog_link li:hover {
  cursor: pointer;
}

.blog_cont .box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  border-bottom: 1px dashed #dc4c35;
}

@media only screen and (max-width: 640px) {
  .blog_cont .box {
    display: block;
  }
}

.blog_cont .box img {
  width: 36% !important;
}

.blog_cont .box div {
  width: 62%;
}

@media only screen and (max-width: 640px) {
  .blog_cont .box div {
    width: 100% !important;
    margin-top: 10px;
  }
}

.news02 {
  display: none;
}

.news02.is-show {
  display: block;
}

.sec_04 {
  display: flex;
  justify-content: cnter;
  align-items: center;
  flex-wrap: wrap;
}

.sec_04 .td_cont {
  width: 30%;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 10px;
}

@media only screen and (max-width: 640px) {
  .sec_04 .td_cont {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_04 .td_cont {
    width: 46% !important;
  }
}

.sec_04 .td_cont .title div {
  border-bottom: 1px solid #dc4c35;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 5px;
}

.sec_04 .td_cont td {
  border: none;
}

.sec_04 .td_cont .txt {
  height: 220px;
}

.sec_05 {
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.sec_05 .td_cont2 {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .td_cont2 {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_05 {
    padding: 0;
  }
}

.td_cont2 {
  padding: 15px;
  background: #f7f7f7;
  margin: 5px;
}

.td_cont2 td div {
  border: none !important;
  font-weight: bold;
}

.td_cont2 td {
  border: none !important;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .td_cont2 td {
    padding: 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .td_cont2 td {
    padding: 0 !important;
  }
}

.td_cont2 .title {
  font-size: 1.3em;
  border-bottom: 1px solid #dc4c35 !important;
  padding-bottom: 5px !important;
}

.td_cont2 .subtitle {
  display: block;
}

.td_cont2 .big {
  font-size: 1.7em;
}

.td_cont2 .p_center div {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .td_cont2 .p_center div {
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .td_cont2 .p_center div {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .td_cont2 .p_center {
    padding: 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .td_cont2 .p_center {
    padding: 0 !important;
  }
}

.td_cont2 .pricetop {
  padding-top: 30px !important;
}

@media only screen and (max-width: 640px) {
  .td_cont2 .pricetop {
    padding-top: 10px !important;
  }
}

.td_cont2 .pricetop2 {
  padding-top: 60px !important;
}

@media only screen and (max-width: 640px) {
  .td_cont2 .pricetop2 {
    padding-top: 10px !important;
  }
}

.td_cont2 .back {
  background: #fff;
  padding: 5px;
  display: inline-block;
  width: 70px;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .td_cont2 .back {
    display: block;
    padding: 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .td_cont2 .back {
    display: block;
    padding: 0 !important;
    width: 100%;
  }
}

.sec_06 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_06 {
    display: block;
  }
  .sec_06 .txt {
    width: 100% !important;
    margin-top: 10px;
  }
  .sec_06 .img {
    width: 100% !important;
  }
  .sec_06 .list4 {
    width: 100% !important;
  }
}

.sec_06 .txt {
  width: 48%;
}

.sec_06 .img {
  width: 48%;
}

.sec_06 .list4 {
  width: 48%;
}

.sec_07 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_07 {
    display: block;
  }
  .sec_07 .flxL {
    width: 100% !important;
  }
  .sec_07 .flxR {
    width: 100% !important;
    margin-top: 10px;
  }
}

.sec_07 .flxL {
  width: 48%;
}

.sec_07 .flxR {
  width: 48%;
}

.sec_08 {
  padding: 0 10px;
}

.sec_09 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_09 {
    display: block;
  }
  .sec_09 .flxL {
    width: 100% !important;
  }
  .sec_09 .flxR {
    width: 100% !important;
  }
}

.sec_09 .flxL {
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sec_09 .flxR {
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sec_09 i {
  color: #FFF533;
  font-size: 3em;
}

.img_100 img {
  width: 100% !important;
}

.td_img04 {
  margin: 5px;
}

.td_img04 td {
  border: none !important;
  padding: 0 !important;
}

.td_img04 .img img {
  width: 100% !important;
  height: 300px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.td_img04 .txt {
  color: #ffffff;
  background: #ea8811;
  padding: 10px !important;
}

.sec_10 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec_10 i {
  color: #FFF533;
  font-size: 3em;
}

.sec_10 .td_img04 {
  width: 50%;
}

.sec_11 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
  padding: 50px 10px;
}

@media only screen and (max-width: 640px) {
  .sec_11 {
    display: block;
    padding: 10px;
  }
  .sec_11 .img {
    width: 100% !important;
    margin: auto !important;
  }
  .sec_11 .clean_txt {
    width: 100% !important;
  }
}

.sec_11 .img {
  width: 30%;
  margin: 10px;
}

.sec_11 .clean_txt {
  width: 30%;
  margin: 10px;
}

.sec_11 .clean_txt .title {
  border-bottom: 1px solid #b0ca71;
  padding-bottom: 5px;
}

.sec_12 {
  background: #69821b;
  padding: 50px 10px;
}

.sec_13 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_13 {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .dl_05 dt {
    width: 100% !important;
  }
  .dl_05 dd {
    width: 100% !important;
    text-align: center;
  }
  .dl_05 dl {
    display: block !important;
  }
}

.dl_05 dt {
  border: none !important;
  display: block !important;
  text-align: center;
}

.dl_05 dd {
  border: none !important;
}

.dl_05 dl {
  border-bottom: 1px dashed #333;
  margin: 10px 0;
}

.triangle {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-top: 60px solid #ea8811;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.imgboxwrap {
  position: relative;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.imgboxwrap:after {
  content: "";
  display: block;
  position: absolute;
  top: 21%;
  right: 0;
  width: 100%;
  height: 50%;
  background: #f7f7f7;
  z-index: -1;
}

.img {
  clear: both;
}

.imgbox {
  justify-content: center;
  text-align: left;
}

.imgbox .box {
  margin: 10px;
  width: 31%;
}

.imgbox .box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 834px) {
  .imgbox .box img {
    width: 100% !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .imgbox .box img {
    width: 100% !important;
  }
}

.imgbox .box h3 {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #b0ca71;
}

.imgbox .box article > div {
  display: none;
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin-top: 20px;
  }
}

.imgbox2 {
  justify-content: center;
  text-align: center;
}

.imgbox2 .box {
  margin: 10px;
  width: 31%;
}

.imgbox2 .box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 834px) {
  .imgbox2 .box img {
    width: 100% !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .imgbox2 .box img {
    width: 100% !important;
  }
}

.imgbox2 .box h3 {
  text-align: center;
  padding: 10px;
  background: #ea8811;
  color: #ffffff;
  font-weight: bold;
}

.imgbox2 .box article > div {
  display: none;
}

@media only screen and (max-width: 640px) {
  .imgbox2 .box {
    width: 100%;
    margin-top: 20px;
  }
}

.dl_02 dt {
  border: none !important;
  text-align: center;
  font-weight: bold;
  background: #b0ca71 !important;
  color: #ffffff;
  border-radius: 10px;
  flex-direction: inherit !important;
  align-items: center !important;
}

@media only screen and (max-width: 640px) {
  .dl_02 dt {
    display: block !important;
    width: 100% !important;
  }
}

.dl_02 dd {
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .dl_02 dd {
    width: 100% !important;
  }
}

.dl_02 dl {
  padding: 10px 0 !important;
}

@media only screen and (max-width: 640px) {
  .dl_02 dl {
    display: block !important;
  }
}

.dl_03 dt {
  border: none !important;
  text-align: center;
  font-weight: bold;
  background: #b0ca71 !important;
  color: #ffffff;
  border-radius: 10px;
  flex-direction: inherit !important;
  align-items: center !important;
}

@media only screen and (max-width: 640px) {
  .dl_03 dt {
    display: block !important;
    width: 100% !important;
  }
}

.dl_03 dd {
  border: none !important;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}

@media only screen and (max-width: 640px) {
  .dl_03 dd {
    width: 100% !important;
  }
}

.dl_03 dl {
  padding: 10px 0 !important;
}

@media only screen and (max-width: 640px) {
  .dl_03 dl {
    display: block !important;
  }
}

.dl_04 dt {
  border: none !important;
  border-bottom: 1px solid #dc4c35 !important;
  display: block !important;
  width: 100% !important;
  font-weight: bold;
}

.dl_04 dd {
  border: none !important;
  width: 100% !important;
}

.dl_04 dl {
  display: block !important;
  padding: 15px 0 !important;
}

.txt_contact .tel2 {
  font-size: 1.5em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.txt_contact .tel2 a {
  color: #333333;
}

.gmap {
  z-index: 0;
}

.gmap iframe {
  width: 100%;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 390px 0 !important;
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg) center;
  background-size: cover;
  z-index: 0;
}

@media only screen and (max-width: 640px) {
  .bg_100per_inner {
    background-position: center;
  }
}

.bg_100per_wrap2 {
  position: relative;
  padding: 360px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap2 {
    padding: 400px 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bg_100per_wrap2 {
    padding: 430px 0 !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #f2f8e3;
  background-size: cover;
  z-index: 0;
}

.bg_100per_wrap3 {
  position: relative;
  padding: 500px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap3 {
    padding: 530px 0 !important;
  }
}

.bg_100per_inner3 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/contents/green_bg.jpg) no-repeat center;
  background-size: cover;
  z-index: 0;
}

@media only screen and (max-width: 640px) {
  .bg_100per_inner3 {
    background-position: center;
  }
}

.bg_100per_wrap4 {
  position: relative;
  padding: 350px 0 !important;
}

.bg_100per_inner4 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/contents/green_bg.jpg) no-repeat center;
  background-size: cover;
  z-index: 0;
}

@media only screen and (max-width: 640px) {
  .bg_100per_inner4 {
    background-position: center;
  }
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #b0ca71;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #ea8811;
  font-weight: bold;
}

#TRANS_ANNOT {
  display: none;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}
